* {
  box-sizing: border-box;
}

html,
body {
  /* background-color: #e7e7e7; */
  /* color: #fff; */
}
strong {
  font-weight: 600;
}
body {
  /* font-family: "Graphik Web", sans-serif; */
  font-weight: 400;
  width: 100%;
  /* height: 100%; */
}

h1 {
  /* font-weight: 600; */
}

h1,
h2,
h3 {
  /* font-family: "Graphik Web", sans-serif; */
  /* font-family: Questrial; */
  font-family: neue-haas-unica, sans-serif;
  font-weight: 900;
  /* text-transform: uppercase; */
}

::-webkit-input-placeholder {
  /* Edge */
  color: black !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black !important;
}

::placeholder {
  color: black !important;
}

.MuiButton-root {
  text-transform: none !important;
}
